import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import { TokenAmount, Pair, Currency, Token, BigintIsh, JSBI } from '@uniswap/sdk'
import { CurrencyAmount, Token as coreToken } from '@uniswap/sdk-core'
import { useMemo, useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { utils } from 'ethers'
import { TWAMMABI } from '../constants'
import { Interface } from '@ethersproject/abi'
import { useActiveWeb3React } from '../hooks'

// import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { getRouterContractShort, getRouterContract, getPairContractConfirmed } from '../utils'
import { WrappedTokenInfo } from '../state/lists/hooks'
// import { Tags, TokenInfo, TokenList } from '@uniswap/token-lists'
// import {  Web3Provider } from '@ethersproject/providers'
import { useSingleCallResult, useSingleContractMultipleData } from '../state/multicall/hooks'
import bn from 'bignumber.js'
import { useBlockNumber } from 'state/application/hooks'
import invariant from 'tiny-invariant'
// import { getRouterContractShort, gePairContract } from '../utils'

const { BNM } = require('./bignumberLIB.js')

const TWAMM_INTERFACE = new Interface(TWAMMABI)

const precisionMultiplier = 10000

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

// see https://stackoverflow.com/a/41102306
const CAN_SET_PROTOTYPE = 'setPrototypeOf' in Object

/**
 * Indicates that the pair has insufficient reserves for a desired output amount. I.e. the amount of output cannot be
 * obtained by sending any amount of input.
 */
export class InsufficientReservesError extends Error {
  public readonly isInsufficientReservesError: true = true

  public constructor() {
    super()
    this.name = this.constructor.name
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype)
  }
}

/**
 * Indicates that the input amount is too small to produce any amount of output. I.e. the amount of input sent is less
 * than the price of a single unit of output after fees.
 */
export class InsufficientInputAmountError extends Error {
  public readonly isInsufficientInputAmountError: true = true

  public constructor() {
    super()
    this.name = this.constructor.name
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype)
  }
}

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

// export function checkPairAddress(chainId: number, library: Web3Provider, account: string, token0Addr: string, token1Addr: string): string {
//   const twammContract = getRouterContract(chainId, library, account)
//   const inputs = [token0Addr, token1Addr]
//   return useSingleCallResult(twammContract, 'obtainPairAddress', inputs)?.result?.[0]
//   // return outAddress
// }

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId, library, account } = useActiveWeb3React()

  const [hasPair, setHasPair] = useState(false)

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const tokenAddrSplit = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return [
          tokenA ? tokenA.address : '0x0000000000000000000000000000000000000000',
          tokenB ? tokenB.address : '0x0000000000000000000000000000000000000000'
        ]
      }),
    [tokens]
  )

  const should_call = tokenAddrSplit?.every(arr => arr?.every(addr => !isZero(addr)))

  library &&
    account &&
    tokenAddrSplit?.length === 1 &&
    tokenAddrSplit[0]?.length &&
    tokenAddrSplit[0][0] !== tokenAddrSplit[0][1] &&
    getRouterContract(chainId, library, account)
      .obtainReserves(tokenAddrSplit[0][0], tokenAddrSplit[0][1])
      .then((res: any) => {
        const { reserve0, reserve1 } = res
        setHasPair(!(isZero(reserve0) && isZero(reserve1)))
      })
      .catch((err: any) => {
        setHasPair(false)
      })

  if (tokenAddrSplit.length > 1 && !hasPair) {
    setHasPair(true)
  }

  const results = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainReserves',
    tokenAddrSplit,
    undefined,
    should_call && hasPair
  )

  const addr = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainPairAddress',
    tokenAddrSplit,
    undefined,
    should_call && hasPair
  )

  return useMemo(() => {
    if (
      should_call &&
      tokenAddrSplit?.length === 1 &&
      tokenAddrSplit[0]?.length &&
      tokenAddrSplit[0][0] === tokenAddrSplit[0][1]
    )
      return [[PairState.INVALID, null]]
    if (!(should_call && hasPair)) return [[PairState.NOT_EXISTS, null]]

    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading || !addr.length || addr.some(e => e.loading)) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]

      const { reserve0, reserve1 } = reserves

      let [token0, token1] = [tokenA, tokenB]

      let new_addr: Pair = new Pair(
        new TokenAmount(token0, reserve0.toString()),
        new TokenAmount(token1, reserve1.toString())
      )

      // @ts-ignore
      new_addr.liquidityToken = new Token(
        // @ts-ignore
        token0.chainId,
        // @ts-ignore
        addr[i]?.result[0],
        18,
        'PUL-LP',
        'Pulsar-LP'
      )

      // @ts-ignore
      new_addr.newGetLiquidityValue = (
        token: Token,
        totalSupply: TokenAmount,
        liquidity: TokenAmount,
        reserveA: any,
        reserveB: any,
        feeOn?: boolean | undefined,
        kLast?: BigintIsh | undefined
      ): TokenAmount => {
        let reserve0
        let reserve1

        if (new_addr.token0.address <= new_addr.token1.address) {
          ;[reserve0, reserve1] = [reserveA, reserveB]
        } else {
          ;[reserve0, reserve1] = [reserveB, reserveA]
        }

        // @ts-ignore
        new_addr.tokenAmounts = [
          new TokenAmount(new_addr.token0, reserve0.toString()),
          new TokenAmount(new_addr.token1, reserve1.toString())
        ]

        return new_addr.getLiquidityValue(token, totalSupply, liquidity, feeOn, kLast)
      }

      // @ts-ignore
      new_addr.setTokenAmounts = (reserveA: any, reserveB: any): Pair => {
        let reserve0
        let reserve1

        if (new_addr.token0.address <= new_addr.token1.address) {
          ;[reserve0, reserve1] = [reserveA, reserveB]
        } else {
          ;[reserve0, reserve1] = [reserveB, reserveA]
        }

        // @ts-ignore
        new_addr.tokenAmounts = [
          new TokenAmount(new_addr.token0, reserve0.toString()),
          new TokenAmount(new_addr.token1, reserve1.toString())
        ]

        return new_addr
      }

      // @ts-ignore
      new_addr.tokenAmounts = [
        new TokenAmount(token0, reserve0.toString()),
        new TokenAmount(token1, reserve1.toString())
      ]

      // @ts-ignore
      new_addr.getLiquidityMinted = (
        totalSupply: CurrencyAmount<coreToken>,
        tokenAmountA: CurrencyAmount<coreToken>,
        tokenAmountB: CurrencyAmount<coreToken>
      ): TokenAmount | undefined => {
        invariant(totalSupply.currency.equals(new_addr.liquidityToken as any), 'LIQUIDITY')
        let liquidity: JSBI

        if (!(tokenAmountA.greaterThan(JSBI.BigInt(0)) || tokenAmountB.greaterThan(JSBI.BigInt(0)))) {
          return undefined
        }
        // @ts-ignore
        let tokenAmounts: TokenAmount[] = [tokenAmountA, tokenAmountB]
        if (totalSupply.equalTo(JSBI.BigInt(0))) {
          liquidity = JSBI.subtract(
            // @ts-ignore
            sqrt(JSBI.multiply(tokenAmounts[0].raw, tokenAmounts[1].raw)),
            JSBI.BigInt(0)
            // MINIMUM_LIQUIDITY
          )
        } else {
          const amount0 = JSBI.divide(
            // @ts-ignore
            JSBI.multiply(tokenAmounts[0].raw, totalSupply?.raw || totalSupply.quotient),
            new_addr.reserve0.raw
          )
          const amount1 = JSBI.divide(
            // @ts-ignore
            JSBI.multiply(tokenAmounts[1].raw, totalSupply?.raw || totalSupply.quotient),
            new_addr.reserve1.raw
          )
          liquidity = JSBI.lessThanOrEqual(amount0, amount1) ? amount0 : amount1
        }
        if (!JSBI.greaterThanOrEqual(liquidity, JSBI.BigInt(0))) {
          throw new InsufficientInputAmountError()
        }
        return new TokenAmount(new_addr.liquidityToken, liquidity)
      }

      return [PairState.EXISTS, new_addr]
    })
  }, [tokens, results, should_call, hasPair, addr, tokenAddrSplit])
}

export function usePairAddresses(currencies: [Currency | undefined, Currency | undefined][]): [string][] {
  const { chainId, library, account } = useActiveWeb3React()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId)
      ]),
    [chainId, currencies]
  )

  const tokenAddrSplit = useMemo(
    () =>
      tokens.map(([tokenA, tokenB]) => {
        return [
          tokenA ? tokenA.address : '0x0000000000000000000000000000000000000000',
          tokenB ? tokenB.address : '0x0000000000000000000000000000000000000000'
        ]
      }),
    [tokens]
  )

  const should_call =
    tokenAddrSplit?.every(arr => arr?.every(addr => !isZero(addr))) &&
    !!tokenAddrSplit &&
    !!tokenAddrSplit?.length &&
    !!tokenAddrSplit[0]?.length &&
    tokenAddrSplit[0][0] !== tokenAddrSplit[0][1]

  const results = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainPairAddress',
    tokenAddrSplit,
    undefined,
    should_call
  )

  return useMemo(() => {
    if (!results.length) return [['0x000']]
    return results.map(result => {
      const { result: address, loading } = result
      return [!loading && address ? address.toString() : '0x0000000000000000000000000000000000000000']
    })
  }, [results])
}

export function isZero(addr: any) {
  return BigNumber.from(addr).eq(ethers.constants.Zero)
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  return usePairs([[tokenA, tokenB]])[0]
}

export function usePairAddress(tokenA?: Currency, tokenB?: Currency): [string] {
  return usePairAddresses([[tokenA, tokenB]])[0]
}

export function usePairBalance(tokenA?: Currency, tokenB?: Currency): number | undefined {
  const { chainId, library, account } = useActiveWeb3React()
  let addr = [wrappedCurrency(tokenA, chainId)?.address || '0x00', wrappedCurrency(tokenB, chainId)?.address || '0x00']

  const results = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainTotalSupply',
    [addr],
    undefined,
    !!addr?.length && !isZero(addr[0]) && !isZero(addr[1])
  )

  return useMemo(() => {
    if (!results?.length) return undefined
    const { result: reserves, loading } = results[0]
    if (loading || !reserves) return undefined
    return reserves ? reserves[0].toString() : undefined
  }, [results])
}

const bigZero = BigNumber.from(0)

function sqrt(value: BigNumber): BigNumber {
  return BigNumber.from(
    new bn(value.toString())
      .sqrt()
      .toFixed()
      .split('.')[0]
  )
}

function computeVirtualBalances(
  tokenAStart: BigNumber,
  tokenBStart: BigNumber,
  tokenAIn: BigNumber,
  tokenBIn: BigNumber
): [BigNumber, BigNumber, BigNumber, BigNumber] {
  let tokenAOut: BigNumber = bigZero
  let tokenBOut: BigNumber = bigZero
  let ammEndTokenA: BigNumber = bigZero
  let ammEndTokenB: BigNumber = bigZero

  if (!tokenAStart.isZero() && !tokenBStart.isZero()) {
    tokenAOut = tokenAStart
      .add(tokenAIn)
      .mul(tokenBIn)
      .div(tokenBStart.add(tokenBIn))
    tokenBOut = tokenBStart
      .add(tokenBIn)
      .mul(tokenAIn)
      .div(tokenAStart.add(tokenAIn))
    ammEndTokenA = tokenAStart.add(tokenAIn).sub(tokenAOut)
    ammEndTokenB = tokenBStart.add(tokenBIn).sub(tokenBOut)
  }

  return [tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB]
}

export function useExecuteVirtualOrdersInterface(
  blockNumbers: number[],
  Pair: Pair | null,
  pairStateChecked: Boolean
): [BigNumber, BigNumber, number, BigNumber, BigNumber, BigNumber, BigNumber] | any {
  //variables
  let tokenAddrSplit = useMemo(() => {
    return [
      [
        Pair ? Pair.token0.address : '0x0000000000000000000000000000000000000000',
        Pair ? Pair.token1.address : '0x0000000000000000000000000000000000000000'
      ]
    ]
  }, [Pair])

  let orderBlockInterval = 5

  const { account, chainId, library } = useActiveWeb3React()

  //get the variable value through the view function
  const [reserveA, setReserveA] = useState(BigNumber.from(0))
  const [reserveB, setReserveB] = useState(BigNumber.from(0))
  const [resLastVirtualOrderBlock, setLastVirtualOrderBlock] = useState(BigNumber.from(0))
  const [currentSalesRateA, setCurrentSalesRateA] = useState(BigNumber.from(0))
  const [currentSalesRateB, setCurrentSalesRateB] = useState(BigNumber.from(0))
  const [rewardFactorA, setRewardFactorA] = useState(BigNumber.from(0))
  const [rewardFactorB, setRewardFactorB] = useState(BigNumber.from(0))
  const [iOrderPoolASalesRateEnding, setIOrderPoolASalesRateEnding] = useState(BigNumber.from(0))
  const [iOrderPoolBSalesRateEnding, setIOrderPoolBSalesRateEnding] = useState(BigNumber.from(0))
  const [endOrderPoolASalesRateEnding, setEndOrderPoolASalesRateEnding] = useState(BigNumber.from(0))
  const [endOrderPoolBSalesRateEnding, setEndOrderPoolBSalesRateEnding] = useState(BigNumber.from(0))
  const [iExpiryBlockArr, setIExpiryBlockArr] = useState<number[][]>([])
  const [nValue, setNValue] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let defaultRes: [BigNumber, BigNumber, number, BigNumber, BigNumber, BigNumber, BigNumber] = [
    BigNumber.from(0),
    BigNumber.from(0),
    0,
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0)
  ]

  let pairAddr = usePairAddress(Pair?.token0, Pair?.token1)

  const should_call =
    tokenAddrSplit?.every(arr => arr?.every(addr => !isZero(addr))) &&
    !!tokenAddrSplit &&
    !!tokenAddrSplit?.length &&
    !!tokenAddrSplit[0]?.length &&
    tokenAddrSplit[0][0] !== tokenAddrSplit[0][1]

  const reserveResults = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainReserves',
    tokenAddrSplit,
    undefined,
    should_call
  )

  const stateResults = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMState',
    [[]],
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0])
  )

  const salesRateEndingResults = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMSalesRateEnding',
    iExpiryBlockArr,
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0]) && iExpiryBlockArr.length > 0
  )

  const blockNumberSalesRateEndingResults = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMSalesRateEnding',
    blockNumbers.map(e => [e]),
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0]) && !!blockNumbers
  )

  const expiriesList = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0]) && !!blockNumbers
  )

  let currentBlockNumber = useBlockNumber()
  let i: number = 0
  return useMemo(() => {
    if (
      !pairStateChecked ||
      !Pair ||
      !blockNumbers ||
      !should_call ||
      !reserveResults[0]?.result ||
      reserveResults.some(e => e.loading) ||
      !stateResults[0]?.result ||
      salesRateEndingResults.some(e => e.loading) ||
      stateResults.some(e => e.loading) ||
      expiriesList.some(e => e.loading) ||
      !expiriesList[0]?.result ||
      !blockNumberSalesRateEndingResults[0]?.result ||
      blockNumberSalesRateEndingResults.some(e => e.loading) ||
      !pairAddr
    ) {
      return Array.from(Array(blockNumbers.length)).map((_, i) => defaultRes)
    }

    let decimalPrecisionA: number
    let decimalPrecisionB: number

    if (Pair.token0.address <= Pair.token1.address) {
      ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token0.decimals, Pair.token1.decimals]
    } else {
      ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token1.decimals, Pair.token0.decimals]
    }

    const {
      lastVirtualOrderBlock,
      tokenASalesRate,
      tokenBSalesRate,
      orderPoolARewardFactor,
      orderPoolBRewardFactor
    } = stateResults[0]?.result

    let resArr: any = []

    !resLastVirtualOrderBlock.eq(lastVirtualOrderBlock) && setLastVirtualOrderBlock(lastVirtualOrderBlock)
    const expiriesArr = expiriesList[0]?.result
    return blockNumbers.map((blockNumber, idx) => {
      if (!currentBlockNumber || blockNumber < currentBlockNumber) return defaultRes
      let lastExpiryBlock = lastVirtualOrderBlock.toNumber() - (lastVirtualOrderBlock.toNumber() % orderBlockInterval)
      let n = (blockNumber - lastExpiryBlock) % orderBlockInterval
      nValue * 1 !== n * 1 && setNValue(n)

      let { orderPoolASalesRateEnding, orderPoolBSalesRateEnding } = blockNumberSalesRateEndingResults[idx]
        ?.result as any

      let temp_orderPoolASalesRateEnding = orderPoolASalesRateEnding
      let temp_orderPoolBSalesRateEnding = orderPoolBSalesRateEnding
      let temp_lastVirtualOrderBlock = lastVirtualOrderBlock
      let temp_currentSalesRateA = tokenASalesRate
      let temp_currentSalesRateB = tokenBSalesRate
      let [temp_reserveA, temp_reserveB] =
        Pair.token0.address < Pair.token1.address
          ? [reserveResults[0]?.result?.reserve0, reserveResults[0]?.result?.reserve1]
          : [reserveResults[0]?.result?.reserve1, reserveResults[0]?.result?.reserve0]

      let temp_rewardFactorA = orderPoolARewardFactor
      let temp_rewardFactorB = orderPoolBRewardFactor

      if (
        pairAddr &&
        pairAddr[0] !== '0x0000000000000000000000000000000000000000' &&
        !isZero(resLastVirtualOrderBlock)
      ) {
        //execute virtual order settlement to blockNumber

        if (blockNumber >= lastExpiryBlock) {
          if (nValue >= 1) {
            let targetArr = Array.from(Array(nValue)).map((_, i) => [++i])
            iExpiryBlockArr?.length !== targetArr?.length && setIExpiryBlockArr(targetArr)

            if (salesRateEndingResults.length < targetArr.length || salesRateEndingResults.some(res => res?.loading))
              return defaultRes

            salesRateEndingResults.map(
              ({ result: { orderPoolASalesRateEnding, orderPoolBSalesRateEnding } }: any, idx) => {
                let iExpiryBlock = lastExpiryBlock + iExpiryBlockArr[0][idx] * orderBlockInterval
                let temp_iOrderPoolASalesRateEnding = orderPoolASalesRateEnding
                let temp_iOrderPoolBSalesRateEnding = orderPoolBSalesRateEnding
                if (
                  BigNumber.from(temp_iOrderPoolASalesRateEnding).gt(bigZero) ||
                  BigNumber.from(temp_iOrderPoolBSalesRateEnding).gt(bigZero)
                ) {
                  //amount sold from virtual trades
                  let blockNumberIncrement = iExpiryBlock - temp_lastVirtualOrderBlock.toNumber()
                  let tokenASellAmount = temp_currentSalesRateA.mul(blockNumberIncrement).div(10000)
                  let tokenBSellAmount = temp_currentSalesRateB.mul(blockNumberIncrement).div(10000)

                  let tokenAOut: BigNumber
                  let tokenBOut: BigNumber
                  let ammEndTokenA: BigNumber
                  let ammEndTokenB: BigNumber

                    //updated balances from sales
                  ;[tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB] = computeVirtualBalances(
                    temp_reserveA,
                    temp_reserveB,
                    tokenASellAmount,
                    tokenBSellAmount
                  )

                  //charge LP fee
                  ammEndTokenA = ammEndTokenA.add(tokenAOut.mul(3).div(1000))
                  ammEndTokenB = ammEndTokenB.add(tokenBOut.mul(3).div(1000))

                  tokenAOut = tokenAOut.mul(997).div(1000)
                  tokenBOut = tokenBOut.mul(997).div(1000)

                  if (!temp_currentSalesRateA.eq(0)) {
                    temp_rewardFactorA = temp_rewardFactorA.add(
                      utils
                        .parseUnits(tokenBOut.toString(), decimalPrecisionB)
                        .mul(10000)
                        .div(temp_currentSalesRateA)
                    )
                  }
                  if (!temp_currentSalesRateB.eq(0)) {
                    temp_rewardFactorB = temp_rewardFactorB.add(
                      utils
                        .parseUnits(tokenAOut.toString(), decimalPrecisionA)
                        .mul(10000)
                        .div(temp_currentSalesRateB)
                    )
                  }

                  //update state
                  temp_reserveA = ammEndTokenA
                  temp_reserveB = ammEndTokenB

                  temp_lastVirtualOrderBlock = BigNumber.from(iExpiryBlock)
                  temp_currentSalesRateA = temp_currentSalesRateA.sub(temp_iOrderPoolASalesRateEnding)
                  temp_currentSalesRateB = temp_currentSalesRateB.sub(temp_iOrderPoolBSalesRateEnding)
                }
              }
            )
            if (blockNumber > temp_lastVirtualOrderBlock) {
              let blockNumberIncrement = blockNumber - temp_lastVirtualOrderBlock.toNumber()
              let tokenASellAmount = temp_currentSalesRateA.mul(blockNumberIncrement).div(10000)
              let tokenBSellAmount = temp_currentSalesRateB.mul(blockNumberIncrement).div(10000)

              let tokenAOut: BigNumber
              let tokenBOut: BigNumber
              let ammEndTokenA: BigNumber
              let ammEndTokenB: BigNumber
              ;[tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB] = computeVirtualBalances(
                temp_reserveA,
                temp_reserveB,
                tokenASellAmount,
                tokenBSellAmount
              )

              //charge LP fee
              ammEndTokenA = ammEndTokenA.add(tokenAOut.mul(3).div(1000))
              ammEndTokenB = ammEndTokenB.add(tokenBOut.mul(3).div(1000))

              tokenAOut = tokenAOut.mul(997).div(1000)
              tokenBOut = tokenBOut.mul(997).div(1000)

              if (!temp_currentSalesRateA.eq(0)) {
                temp_rewardFactorA = temp_rewardFactorA.add(
                  utils
                    .parseUnits(tokenBOut.toString(), decimalPrecisionB)
                    .mul(10000)
                    .div(temp_currentSalesRateA)
                )
              }
              if (!temp_currentSalesRateB.eq(0)) {
                temp_rewardFactorB = temp_rewardFactorB.add(
                  utils
                    .parseUnits(tokenAOut.toString(), decimalPrecisionA)
                    .mul(10000)
                    .div(temp_currentSalesRateB)
                )
              }

              //update state
              temp_reserveA = ammEndTokenA
              temp_reserveB = ammEndTokenB

              temp_lastVirtualOrderBlock = BigNumber.from(blockNumber)
              temp_currentSalesRateA = temp_currentSalesRateA.sub(temp_orderPoolASalesRateEnding)
              temp_currentSalesRateB = temp_currentSalesRateB.sub(temp_orderPoolBSalesRateEnding)
            }
          } else {
            if (blockNumber > temp_lastVirtualOrderBlock) {
              let blockNumberIncrement = blockNumber - temp_lastVirtualOrderBlock.toNumber()
              let tokenASellAmount = temp_currentSalesRateA.mul(blockNumberIncrement).div(10000)
              let tokenBSellAmount = temp_currentSalesRateB.mul(blockNumberIncrement).div(10000)
              let tokenAOut: BigNumber
              let tokenBOut: BigNumber
              let ammEndTokenA: BigNumber
              let ammEndTokenB: BigNumber
              ;[tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB] = computeVirtualBalances(
                temp_reserveA,
                temp_reserveB,
                tokenASellAmount,
                tokenBSellAmount
              )

              //charge LP fee
              ammEndTokenA = ammEndTokenA.add(tokenAOut.mul(3).div(1000))
              ammEndTokenB = ammEndTokenB.add(tokenBOut.mul(3).div(1000))

              tokenAOut = tokenAOut.mul(997).div(1000)
              tokenBOut = tokenBOut.mul(997).div(1000)

              if (!temp_currentSalesRateA.eq(0)) {
                temp_rewardFactorA = temp_rewardFactorA.add(
                  utils
                    .parseUnits(tokenBOut.toString(), decimalPrecisionB)
                    .mul(10000)
                    .div(temp_currentSalesRateA)
                )
              }
              if (!temp_currentSalesRateB.eq(0)) {
                temp_rewardFactorB = temp_rewardFactorB.add(
                  utils
                    .parseUnits(tokenAOut.toString(), decimalPrecisionA)
                    .mul(10000)
                    .div(temp_currentSalesRateB)
                )
              }

              //update state
              temp_reserveA = ammEndTokenA
              temp_reserveB = ammEndTokenB

              temp_lastVirtualOrderBlock = BigNumber.from(blockNumber)
              temp_currentSalesRateA = temp_currentSalesRateA.sub(temp_orderPoolASalesRateEnding)
              temp_currentSalesRateB = temp_currentSalesRateB.sub(temp_orderPoolBSalesRateEnding)
            }
          }
        }
      }

      return [
        temp_reserveA,
        temp_reserveB,
        resLastVirtualOrderBlock.toNumber(),
        temp_currentSalesRateA,
        temp_currentSalesRateB,
        temp_rewardFactorA,
        temp_rewardFactorB
      ]
    })
  }, [
    reserveResults,
    stateResults,
    should_call,
    pairAddr,
    blockNumberSalesRateEndingResults,
    pairStateChecked,
    Pair,
    blockNumbers,
    salesRateEndingResults,
    resLastVirtualOrderBlock,
    defaultRes,
    currentBlockNumber,
    orderBlockInterval,
    nValue,
    iExpiryBlockArr,
    expiriesList
  ])
}

export function useExecuteVirtualOrdersInterfaceV2(
  expiryBlockNumbersFromLast: number[],
  Pair: Pair | null,
  pairStateChecked: Boolean
): [BigNumber, BigNumber, number, BigNumber, BigNumber, BigNumber, BigNumber] | any {
  //variables
  let tokenAddrSplit = useMemo(() => {
    return [
      [
        Pair ? Pair.token0.address : '0x0000000000000000000000000000000000000000',
        Pair ? Pair.token1.address : '0x0000000000000000000000000000000000000000'
      ]
    ]
  }, [Pair])

  let orderBlockInterval = 5

  const { account, chainId, library } = useActiveWeb3React()

  //get the variable value through the view function
  const [reserveA, setReserveA] = useState(BigNumber.from(0))
  const [reserveB, setReserveB] = useState(BigNumber.from(0))
  const [resLastVirtualOrderBlock, setLastVirtualOrderBlock] = useState(BigNumber.from(0))
  const [currentSalesRateA, setCurrentSalesRateA] = useState(BigNumber.from(0))
  const [currentSalesRateB, setCurrentSalesRateB] = useState(BigNumber.from(0))
  const [rewardFactorA, setRewardFactorA] = useState(BigNumber.from(0))
  const [rewardFactorB, setRewardFactorB] = useState(BigNumber.from(0))
  const [iOrderPoolASalesRateEnding, setIOrderPoolASalesRateEnding] = useState(BigNumber.from(0))
  const [iOrderPoolBSalesRateEnding, setIOrderPoolBSalesRateEnding] = useState(BigNumber.from(0))
  const [endOrderPoolASalesRateEnding, setEndOrderPoolASalesRateEnding] = useState(BigNumber.from(0))
  const [endOrderPoolBSalesRateEnding, setEndOrderPoolBSalesRateEnding] = useState(BigNumber.from(0))
  // const [iExpiryBlockArr, setIExpiryBlockArr] = useState<number[][]>([])
  const [nValue, setNValue] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let defaultRes: [BigNumber, BigNumber, number, BigNumber, BigNumber, BigNumber, BigNumber] = [
    BigNumber.from(0),
    BigNumber.from(0),
    0,
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0),
    BigNumber.from(0)
  ]

  let pairAddr = usePairAddress(Pair?.token0, Pair?.token1)

  const should_call =
    tokenAddrSplit?.every(arr => arr?.every(addr => !isZero(addr))) &&
    !!tokenAddrSplit &&
    !!tokenAddrSplit?.length &&
    !!tokenAddrSplit[0]?.length &&
    tokenAddrSplit[0][0] !== tokenAddrSplit[0][1]

  const reserveResults = useSingleContractMultipleData(
    library && account ? getRouterContract(chainId, library, account) : undefined,
    'obtainReserves',
    tokenAddrSplit,
    undefined,
    should_call
  )

  const stateResults = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMState',
    [[]],
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0])
  )

  const blockNumberSalesRateEndingResults = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMSalesRateEnding',
    expiryBlockNumbersFromLast.map(e => [e]),
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0]) //&& !!expiryBlockNumbersFromLast
  )

  let currentBlockNumber = useBlockNumber()

  const currentBlockSalesRateEnding = useSingleContractMultipleData(
    !isZero(pairAddr[0]) && library && account ? getPairContractConfirmed(pairAddr[0], library, account) : undefined,
    'getTWAMMSalesRateEnding',
    [[currentBlockNumber]],
    undefined,
    should_call && !!reserveResults[0]?.result && !isZero(pairAddr[0]) //&& !!currentBlockNumber
  )

  return useMemo(() => {
    let blockToUpdatedStatesMap: any = {}
    if (
      !pairStateChecked ||
      !Pair ||
      !expiryBlockNumbersFromLast ||
      expiryBlockNumbersFromLast[0] == 0 ||
      !should_call ||
      !reserveResults[0]?.result ||
      reserveResults.some(e => e.loading) ||
      !stateResults[0]?.result ||
      stateResults.some(e => e.loading) ||
      !blockNumberSalesRateEndingResults[0]?.result ||
      blockNumberSalesRateEndingResults.some(e => e.loading) ||
      !pairAddr ||
      pairAddr[0] == '0x0000000000000000000000000000000000000000' ||
      !currentBlockSalesRateEnding[0]?.result ||
      currentBlockSalesRateEnding.some(e => e.loading) ||
      !currentBlockNumber
    ) {
      return defaultRes
    }

    let decimalPrecisionA: number
    let decimalPrecisionB: number

    if (Pair.token0.address <= Pair.token1.address) {
      ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token0.decimals, Pair.token1.decimals]
    } else {
      ;[decimalPrecisionA, decimalPrecisionB] = [Pair.token1.decimals, Pair.token0.decimals]
    }

    const {
      lastVirtualOrderBlock,
      tokenASalesRate,
      tokenBSalesRate,
      orderPoolARewardFactor,
      orderPoolBRewardFactor
    } = stateResults[0]?.result

    !resLastVirtualOrderBlock.eq(lastVirtualOrderBlock) && setLastVirtualOrderBlock(lastVirtualOrderBlock)

    let lastExpiryBlock = lastVirtualOrderBlock.toNumber() - (lastVirtualOrderBlock.toNumber() % orderBlockInterval)

    let temp_orderPoolASalesRateEnding: BigNumber
    let temp_orderPoolBSalesRateEnding: BigNumber
    let temp_lastVirtualOrderBlock: BigNumber
    let temp_currentSalesRateA: BigNumber
    let temp_currentSalesRateB: BigNumber
    let temp_reserveA: BigNumber
    let temp_reserveB: BigNumber
    let temp_rewardFactorA: BigNumber
    let temp_rewardFactorB: BigNumber
    temp_lastVirtualOrderBlock = lastVirtualOrderBlock
    temp_currentSalesRateA = tokenASalesRate
    temp_currentSalesRateB = tokenBSalesRate
    if (Pair.token0.address < Pair.token1.address) {
      temp_reserveA = reserveResults[0]?.result?.reserve0
      temp_reserveB = reserveResults[0]?.result?.reserve1
    } else {
      temp_reserveA = reserveResults[0]?.result?.reserve1
      temp_reserveB = reserveResults[0]?.result?.reserve0
    }
    temp_rewardFactorA = utils.parseUnits(orderPoolARewardFactor.toString(), decimalPrecisionA + decimalPrecisionB)
    temp_rewardFactorB = utils.parseUnits(orderPoolBRewardFactor.toString(), decimalPrecisionA + decimalPrecisionB)

    let blockNumber: number
    if (expiryBlockNumbersFromLast.length > 0) {
      //no order expiries since last executed
      for (let idx = 0; idx < expiryBlockNumbersFromLast.length; idx++) {
        blockNumber = expiryBlockNumbersFromLast[idx]
        if (blockNumber >= currentBlockNumber) {
          // blockToUpdatedStatesMap[blockNumber.toString()] = defaultRes
          break
        }
        if (blockNumber < currentBlockNumber && blockNumber > lastExpiryBlock) {
          let { orderPoolASalesRateEnding, orderPoolBSalesRateEnding } = blockNumberSalesRateEndingResults[idx]
            ?.result as any

          temp_orderPoolASalesRateEnding = orderPoolASalesRateEnding
          temp_orderPoolBSalesRateEnding = orderPoolBSalesRateEnding

          //execute virtual order settlement to blockNumber

          if (
            BigNumber.from(temp_orderPoolASalesRateEnding).gt(bigZero) ||
            BigNumber.from(temp_orderPoolBSalesRateEnding).gt(bigZero)
          ) {
            //amount sold from virtual trades
            let blockNumberIncrement = blockNumber - temp_lastVirtualOrderBlock.toNumber()
            let tokenASellAmount = temp_currentSalesRateA.mul(blockNumberIncrement).div(10000)
            let tokenBSellAmount = temp_currentSalesRateB.mul(blockNumberIncrement).div(10000)

            let tokenAOut: BigNumber
            let tokenBOut: BigNumber
            let ammEndTokenA: BigNumber
            let ammEndTokenB: BigNumber

              //updated balances from sales
            ;[tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB] = computeVirtualBalances(
              temp_reserveA,
              temp_reserveB,
              tokenASellAmount,
              tokenBSellAmount
            )

            //charge LP fee
            ammEndTokenA = ammEndTokenA.add(tokenAOut.mul(3).div(1000))
            ammEndTokenB = ammEndTokenB.add(tokenBOut.mul(3).div(1000))

            tokenAOut = tokenAOut.mul(997).div(1000)
            tokenBOut = tokenBOut.mul(997).div(1000)

            if (!temp_currentSalesRateA.eq(0)) {
              temp_rewardFactorA = temp_rewardFactorA.add(
                utils
                  .parseUnits(tokenBOut.toString(), decimalPrecisionA * 2 + 18 + decimalPrecisionB - decimalPrecisionA)
                  .mul(10000)
                  .div(temp_currentSalesRateA)
              )
            }
            if (!temp_currentSalesRateB.eq(0)) {
              temp_rewardFactorB = temp_rewardFactorB.add(
                utils
                  .parseUnits(tokenAOut.toString(), decimalPrecisionB * 2 + 18 + decimalPrecisionA - decimalPrecisionB)
                  .mul(10000)
                  .div(temp_currentSalesRateB)
              )
            }

            //update state
            temp_reserveA = ammEndTokenA
            temp_reserveB = ammEndTokenB

            temp_lastVirtualOrderBlock = BigNumber.from(blockNumber)
            temp_currentSalesRateA = temp_currentSalesRateA.sub(temp_orderPoolASalesRateEnding)
            temp_currentSalesRateB = temp_currentSalesRateB.sub(temp_orderPoolBSalesRateEnding)
            blockToUpdatedStatesMap[blockNumber.toString()] = [
              temp_reserveA,
              temp_reserveB,
              // resLastVirtualOrderBlock.toNumber(),
              lastVirtualOrderBlock.toNumber(),
              temp_currentSalesRateA,
              temp_currentSalesRateB,
              temp_rewardFactorA,
              temp_rewardFactorB
            ]
          }
        }
      }
    }
    if (currentBlockNumber >= temp_lastVirtualOrderBlock.toNumber()) {
      // change > to >= that handles equal case
      let { orderPoolASalesRateEnding, orderPoolBSalesRateEnding } = currentBlockSalesRateEnding[0]?.result as any
      temp_orderPoolASalesRateEnding = orderPoolASalesRateEnding
      temp_orderPoolBSalesRateEnding = orderPoolBSalesRateEnding
      let blockNumberIncrement = currentBlockNumber - temp_lastVirtualOrderBlock.toNumber()
      let tokenASellAmount = temp_currentSalesRateA.mul(blockNumberIncrement).div(10000)
      let tokenBSellAmount = temp_currentSalesRateB.mul(blockNumberIncrement).div(10000)

      let tokenAOut: BigNumber
      let tokenBOut: BigNumber
      let ammEndTokenA: BigNumber
      let ammEndTokenB: BigNumber
      ;[tokenAOut, tokenBOut, ammEndTokenA, ammEndTokenB] = computeVirtualBalances(
        temp_reserveA,
        temp_reserveB,
        tokenASellAmount,
        tokenBSellAmount
      )
      //charge LP fee
      ammEndTokenA = ammEndTokenA.add(tokenAOut.mul(3).div(1000))
      ammEndTokenB = ammEndTokenB.add(tokenBOut.mul(3).div(1000))

      tokenAOut = tokenAOut.mul(997).div(1000)
      tokenBOut = tokenBOut.mul(997).div(1000)

      if (!temp_currentSalesRateA.eq(0)) {
        temp_rewardFactorA = temp_rewardFactorA.add(
          utils
            .parseUnits(tokenBOut.toString(), decimalPrecisionA * 2 + 18 + decimalPrecisionB - decimalPrecisionA)
            .mul(10000)
            .div(temp_currentSalesRateA.toString())
        )
      }
      if (!temp_currentSalesRateB.eq(0)) {
        temp_rewardFactorB = temp_rewardFactorB.add(
          utils
            .parseUnits(tokenAOut.toString(), decimalPrecisionB * 2 + 18 + decimalPrecisionA - decimalPrecisionB)
            .mul(10000)
            .div(temp_currentSalesRateB.toString())
        )
      }

      //update state
      temp_reserveA = ammEndTokenA
      temp_reserveB = ammEndTokenB

      temp_lastVirtualOrderBlock = BigNumber.from(currentBlockNumber)
      temp_currentSalesRateA = temp_currentSalesRateA.sub(temp_orderPoolASalesRateEnding)
      temp_currentSalesRateB = temp_currentSalesRateB.sub(temp_orderPoolBSalesRateEnding)

      blockToUpdatedStatesMap[currentBlockNumber.toString()] = [
        temp_reserveA,
        temp_reserveB,
        lastVirtualOrderBlock.toNumber(),
        temp_currentSalesRateA,
        temp_currentSalesRateB,
        temp_rewardFactorA,
        temp_rewardFactorB
      ]

      return blockToUpdatedStatesMap
    }
  }, [
    reserveResults,
    stateResults,
    should_call,
    pairAddr,
    blockNumberSalesRateEndingResults,
    pairStateChecked,
    Pair,
    resLastVirtualOrderBlock,
    currentBlockNumber,
    orderBlockInterval,
    expiryBlockNumbersFromLast,
    currentBlockSalesRateEnding,
    defaultRes
  ])
}
