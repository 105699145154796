// import { ChainId } from '@uniswap/sdk'
import { ChainId } from 'constants/chains'
import React from 'react'
// import { ChainId, TokenAmount } from '@uniswap/sdk'
// import React, { useState } from 'react'
import { Text } from 'rebass'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'
import LogoDark from '../../assets/svg/logo.svg'
import { useActiveWeb3React } from '../../hooks'
import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'
import { ExternalLink } from '../../theme'
// import { useETHBalances, useAggregatePulBalance } from '../../state/wallet/hooks'
// import { CardNoise } from '../earn/styled'
// import { CountUp } from 'use-count-up'
// import { TYPE, ExternalLink } from '../../theme'

import { YellowCard } from '../Card'
import { Moon, Sun } from 'react-feather'
import Menu from '../Menu'

import Row, { RowFixed } from '../Row'
import Web3Status from '../Web3Status'
import NetworkMenu from '../NetworkMenu'
import { SupportedChainId, CHAIN_IDS_TO_NAMES } from 'constants/chains'
import { getChainInfo } from 'constants/chainInfo'
// import ClaimModal from '../claim/ClaimModal'
// import { useToggleSelfClaimModal, useShowClaimPopup } from '../../state/application/hooks'
// import { useUserHasAvailableClaim } from '../../state/claim/hooks'
// import { useUserHasSubmittedClaim } from '../../state/transactions/hooks'
// import { Dots } from '../swap/styleds'
// import Modal from '../Modal'
// import PulBalanceContent from './PulBalanceContent'
// import usePrevious from '../../hooks/usePrevious'

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 3px solid linear-gradient(to right, #0261f5, #2663eb);
  padding: 1rem;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    padding: 0 1rem;
    width: calc(100%);
    position: relative;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        padding: 0.5rem 1rem;
  `}
`

const HeaderBottomLine = styled.div`
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 3px;
  z-index: 3;
  background-image: linear-gradient(to right, #0261f5, #6661f6);
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: row;
    justify-content: space-between;
    justify-self: center;
    width: 100%;
    max-width: 960px;
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 99;
    height: 72px;
    border-radius: 12px 12px 0 0;
    background-color: ${({ theme }) => theme.bg1};
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;
`

const HeaderRow = styled(RowFixed)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const HeaderLinks = styled(Row)`
  justify-content: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem 0 1rem 1rem;
    justify-content: flex-end;
`};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.primary4 : theme.primary3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
`

// const PULAmount = styled(AccountElement)`
//   color: white;
//   padding: 4px 8px;
//   height: 36px;
//   font-weight: 500;
//   background-color: ${({ theme }) => theme.bg3};
//   background: radial-gradient(174.47% 188.91% at 1.84% 0%, #0261f5 0%, #2172e5 100%), #edeef2;
// `

// const PULWrapper = styled.span`
//   width: fit-content;
//   position: relative;
//   cursor: pointer;

//   :hover {
//     opacity: 0.8;
//   }

//   :active {
//     opacity: 0.9;
//   }
// `

const HideSmall = styled.span`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `};
`

// const NetworkCard = styled(YellowCard)`
const NetworkCard = styled.span`
  border-radius: 12px;
  /* padding: 8px 12px; */
  color: ${({ theme }) => theme.yellow2};
  padding: 0;
  font-size: 16px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    margin-right: 0.5rem;
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const PulIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.customBlue2};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.customBlue2)};
  }
`

const StyledExternalLink = styled(ExternalLink).attrs({
  activeClassName
})<{ isActive?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 1rem;
  width: fit-content;
  margin: 0 12px;
  font-weight: 500;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.customBlue2};
  }

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.customBlue2)};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: none;
`}
`

export const StyledMenuButton = styled.button`
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg3};
  margin-left: 8px;
  padding: 0.15rem 0.5rem;
  border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
  > * {
    stroke: ${({ theme }) => theme.text1};
  }
`

const NETWORK_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.MANTLE_GOERLI]: 'Mantle Görli'
}

const BRIDGE_LABELS: { [chainId in ChainId]?: string } = {
  [ChainId.MANTLE]: 'Mantle Bridge',
  [ChainId.MANTLE_GOERLI]: 'Mantle Görli Bridge',
  [ChainId.ARBITRUM_ONE]: 'Arbitrum Bridge',
  [ChainId.ARBITRUM_GOERLI]: 'Arbitrum Görli Bridge',
  [ChainId.ARBITRUM_RINKEBY]: 'Arbitrum Rinkeby Bridge'
}

const BRIDGE_LINK: { [chainId in ChainId]: string } = {
  1: '',
  3: '',
  4: '',
  5: '',
  42: '',
  [ChainId.MANTLE]: 'https://bridge.mantle.xyz',
  [ChainId.MANTLE_GOERLI]: 'https://bridge.testnet.mantle.xyz',
  [ChainId.ARBITRUM_ONE]: 'https://bridge.arbitrum.io',
  [ChainId.ARBITRUM_GOERLI]: 'https://bridge.arbitrum.io',
  [ChainId.ARBITRUM_RINKEBY]: 'https://bridge.arbitrum.io'
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  const [darkMode, toggleDarkMode] = useDarkModeManager()

  // const toggleClaimModal = useToggleSelfClaimModal()

  // const availableClaim: boolean = useUserHasAvailableClaim(account)

  // const { claimTxn } = useUserHasSubmittedClaim(account ?? undefined)

  // const aggregateBalance: TokenAmount | undefined = useAggregatePulBalance()

  // const [showPulBalanceModal, setShowPulBalanceModal] = useState(false)
  // const showClaimPopup = useShowClaimPopup()

  // const countUpValue = aggregateBalance?.toFixed(0) ?? '0'
  // const countUpValuePrevious = usePrevious(countUpValue) ?? '0'

  return (
    <>
      <HeaderFrame>
        {/* <ClaimModal />
      <Modal isOpen={showPulBalanceModal} onDismiss={() => setShowPulBalanceModal(false)}>
        <PulBalanceContent setShowPulBalanceModal={setShowPulBalanceModal} />
      </Modal> */}
        <HeaderRow>
          <Title href=".">
            <PulIcon>
              <img width={'30px'} src={darkMode ? LogoDark : Logo} alt="logo" />
            </PulIcon>
          </Title>
          <HeaderLinks>
            <StyledNavLink id={`swap-nav-link`} to={'/instantswap'}>
              {t('instantSwap')}
            </StyledNavLink>
            <StyledNavLink id={`term-swap-nav-link`} to={'/termswap'}>
              {t('termSwap')}
            </StyledNavLink>
            <StyledNavLink
              id={`pool-nav-link`}
              to={'/pool'}
              isActive={(match, { pathname }) =>
                Boolean(match) ||
                pathname.startsWith('/add') ||
                pathname.startsWith('/remove') ||
                pathname.startsWith('/create') ||
                pathname.startsWith('/find')
              }
            >
              {t('pool')}
            </StyledNavLink>
            {/* <StyledNavLink id={`stake-nav-link`} to={'/pul'}>
            Farm
          </StyledNavLink> */}
            {/* <StyledNavLink id={`stake-nav-link`} to={'/vote'}>
            Vote
          </StyledNavLink> */}
            {/* <HideSmall>
              {chainId &&
                // @ts-ignore
                BRIDGE_LABELS[chainId] && (
                  <StyledExternalLink id={`stake-nav-link`} href={'https://bridge.testnet.mantle.xyz/'}>
                    {t('Bridge')} <span style={{ fontSize: '11px' }}>↗</span>
                  </StyledExternalLink>
                )}
            </HideSmall> */}
            {/* <StyledExternalLink id={`stake-nav-link`} href={'https://info.pulsarswap.com'}>
              {t('charts')} <span style={{ fontSize: '11px' }}></span>
            </StyledExternalLink> */}
          </HeaderLinks>
        </HeaderRow>
        <HeaderControls>
          <HeaderElement>
            <HideSmall>
              {chainId &&
                // @ts-ignore
                BRIDGE_LABELS[chainId] && (
                  <StyledExternalLink id={`stake-nav-link`} href={BRIDGE_LINK[chainId]}>
                    {t('Bridge')} <span style={{ fontSize: '11px' }}>↗</span>
                  </StyledExternalLink>
                )}
            </HideSmall>
            <HideSmall>
              {chainId &&
                // @ts-ignore
                NETWORK_LABELS[chainId] && (
                  <StyledExternalLink id={`stake-nav-link`} href={'https://faucet.pulsarswap.com/'}>
                    {t('Faucet')} <span style={{ fontSize: '11px' }}>↗</span>
                  </StyledExternalLink>
                )}
            </HideSmall>
            <HideSmall>
              <NetworkMenu>
                {chainId && (
                  <NetworkCard title={getChainInfo(chainId)?.label}>{getChainInfo(chainId)?.label}</NetworkCard>
                )}
              </NetworkMenu>
            </HideSmall>
            {/* {availableClaim && !showClaimPopup && (
            <PULWrapper onClick={toggleClaimModal}>
              <PULAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                <TYPE.white padding="0 2px">
                  {claimTxn && !claimTxn?.receipt ? <Dots>Claiming PUL</Dots> : 'Claim PUL'}
                </TYPE.white>
              </PULAmount>
              <CardNoise />
            </PULWrapper>
          )}
          {!availableClaim && aggregateBalance && (
            <PULWrapper onClick={() => setShowPulBalanceModal(true)}>
              <PULAmount active={!!account && !availableClaim} style={{ pointerEvents: 'auto' }}>
                {account && (
                  <HideSmall>
                    <TYPE.white
                      style={{
                        paddingRight: '.4rem'
                      }}
                    >
                      <CountUp
                        key={countUpValue}
                        isCounting
                        start={parseFloat(countUpValuePrevious)}
                        end={parseFloat(countUpValue)}
                        thousandsSeparator={','}
                        duration={1}
                      />
                    </TYPE.white>
                  </HideSmall>
                )}
                PUL
              </PULAmount>
              <CardNoise />
            </PULWrapper>
          )} */}
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} {chainId === 5000 || chainId === 5001 ? 'MNT' : 'ETH'}
                </BalanceText>
              ) : null}
              {/* <BalanceText style={{ flexShrink: 0 }} pl="0 rem" pr="0.5rem" fontWeight={500}>
                {chainId === 5000 || chainId === 5001 ? 'MNT' : 'ETH'}
              </BalanceText> */}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <StyledMenuButton onClick={() => toggleDarkMode()}>
              {darkMode ? <Moon size={20} /> : <Sun size={20} />}
            </StyledMenuButton> */}
            <Menu />
          </HeaderElementWrap>
        </HeaderControls>
        <HeaderBottomLine />
      </HeaderFrame>
    </>
  )
}
