import React, { useCallback, useState, useEffect } from 'react'
import { Currency, Pair, TokenAmount } from '@uniswap/sdk'
import styled from 'styled-components'
import { AutoRow } from '../../components/Row'
// import { ExternalLink, X } from 'react-feather'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink } from '../../theme'
import PIConfirm from '../../components/Modal/PIConfirm'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'state'
import { darken } from 'polished'
import SwapLongTermHeader from '../../components/swap/SwapLongTermHeader'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from '../../state/swap/hooks'
import { blockInput, Field } from '../../state/swap/actions'
import { LightQuestionHelper } from '../../components/QuestionHelper'
import { useSingleCallResult, useSingleContractMultipleData, Result } from '../../state/multicall/hooks'
import { getRouterContractShort, getPairContractConfirmed } from '../../utils'
import {
  PairState,
  usePair,
  usePairAddress,
  usePairBalance,
  useExecuteVirtualOrdersInterface,
  useExecuteVirtualOrdersInterfaceV2
} from '../../data/Reserves'
import { useBlockNumber } from '../../state/application/hooks'
import { useETHBalances, useTokenBalance, useTokenBalances } from '../../state/wallet/hooks'
import { isZero } from '../../data/Reserves'
import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { useActiveWeb3React } from '../../hooks'
import { Col, InputNumber, Row, Slider } from 'antd'
import { calculateGasMargin, calculateSlippageAmount, getRouterContract, getTWAMMContract } from '../../utils'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { getEtherscanLink } from '../../utils'
import { BigNumber } from 'ethers'

const IntegerStep = () => {
  const [inputValue, setInputValue] = useState(1)

  const onChange = (newValue: number) => {
    setInputValue(newValue)
  }

  return (
    <Row>
      <Col span={12}>
        <Slider min={1} max={20} onChange={onChange} value={typeof inputValue === 'number' ? inputValue : 0} />
      </Col>
      <Col span={4}>
        <InputNumber min={1} max={20} style={{ margin: '0 16px' }} value={inputValue} onChange={onChange} />
      </Col>
    </Row>
  )
}

const XIconWrapper = styled.div<{ bg?: string }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  width: 40px;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => darken(0.03, theme.primary5)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary5)};
    background-color: ${({ theme }) => darken(0.05, theme.primary5)};
  }

  background-color: ${({ theme, bg }) => (bg ? bg : theme.lightBlue)};
  background-image: ${({ theme, bg }) => (bg ? bg : theme.lightBlue)};
  color: ${({ theme }) => theme.white};

  #show-note {
    padding: 2px 8px;
    opacity: 0;
    transition: 0.35;
    font-size: 14px;
    background-color: ${({ theme }) => theme.black};
    display: block;
    position: absolute;
    margin-top: -70px;
    border-radius: 2px;
  }

  &:hover #show-note {
    color: ${({ theme }) => theme.white};
    opacity: 0.65;
  }
`

interface Props {
  currency?: Currency | null
  buyCurrency?: Currency | null
  pair?: Pair | null
}

const ListWrapper = styled.div`
  /* padding: 8px 0 15px 0; */
  width: 100%;
`

const ItemWrapper = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0px 1.5rem 0px 1.5rem;
  margin-top: 1rem;
  width: 100%;
  min-width: 100px;
  color: ${({ theme }) => theme.text2};
`

const ItemWrapperRow = styled(ItemWrapper)`
  flex-direction: row;
  a {
    display: flex;
    margin-left: 0.6rem;
  }
`

const ItemsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
`

const ItemTitle = styled.div`
  font-weight: bold;
  display: flex;
`

const ItemInfo = styled.div`
  margin-left: 0.6rem;
  font-weight: bold;
  color: ${({ theme }) => theme.text3};
`

const ItemDetail = styled.div`
  display: flex;
`

export const StateInfo: React.FC<Props> = ({ currency, buyCurrency, pair }) => {
  const dispatch = useDispatch<AppDispatch>()
  const { chainId, library, account } = useActiveWeb3React()
  const [{ showConfirm }, setConfirmState] = useState<{
    showConfirm: boolean
  }>({
    showConfirm: false
  })
  const router = getTWAMMContract(chainId, library, account)
  const addTransaction = useTransactionAdder()
  const [previousBlock, setPreviousBlock] = useState(0)

  const [inputValue, setInputValue] = useState(1)

  const onChange = (newValue: number) => {
    newValue !== 0 && setInputValue(newValue)
  }

  const onExecuteVirtualOrders = () => {
    setConfirmState({ showConfirm: true })
  }

  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    resTrade
  } = useDerivedSwapInfo()

  let currentBlockNumber = useBlockNumber()

  const [pairState, Pair] = usePair(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined)
  const pairAddr = usePairAddress(currencies[Field.INPUT], currencies[Field.OUTPUT])[0]
  const userPairABalance = useTokenBalance(isZero(pairAddr) ? undefined : pairAddr, Pair?.token0)
  const userPairBBalance = useTokenBalance(isZero(pairAddr) ? undefined : pairAddr, Pair?.token1)

  const pairBalance = usePairBalance(currencies[Field.INPUT] ?? undefined, currencies[Field.OUTPUT] ?? undefined)

  const expiriesList = useSingleContractMultipleData(
    Pair && library && account ? getPairContractConfirmed(Pair.liquidityToken.address, library, account) : undefined,
    'getExpiriesSinceLastExecuted',
    [[]],
    undefined,
    !!Pair
  )
  const preprocessedExpiryList = expiriesList[0]?.result as any
  let reserveA
  let reserveB
  let lastVirtualOrderBlock: number
  let currentSalesRateA
  let currentSalesRateB
  let rewardFactorA
  let rewardFactorB
  ///////////////////////// below for old changes /////////////////////////////
  const stateInfoSinceLastExecutionMap = useExecuteVirtualOrdersInterfaceV2(
    !!preprocessedExpiryList ? preprocessedExpiryList[0].map((e: BigNumber) => e?.toNumber()) : [0],
    Pair,
    pairState === PairState.EXISTS
  )
  if (!!currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    ;[
      reserveA,
      reserveB,
      lastVirtualOrderBlock,
      currentSalesRateA,
      currentSalesRateB,
      rewardFactorA,
      rewardFactorB
    ] = stateInfoSinceLastExecutionMap[currentBlockNumber.toString()]
  } else {
    ;[reserveA, reserveB, lastVirtualOrderBlock, currentSalesRateA, currentSalesRateB, rewardFactorA, rewardFactorB] = [
      BigNumber.from(0),
      BigNumber.from(0),
      0,
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0),
      BigNumber.from(0)
    ]
  }

  let reserveInPut
  let reserveOutPut

  if (Pair && currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    if (Pair.token0.address <= Pair.token1.address) {
      ;[reserveInPut, reserveOutPut] = [reserveA, reserveB]
    } else {
      ;[reserveInPut, reserveOutPut] = [reserveB, reserveA]
    }
  } else {
    ;[reserveInPut, reserveOutPut] = [undefined, undefined]
  }

  let currentSalesRateInPut
  let currentSalesRateOutPut

  if (Pair && currentBlockNumber && !Array.isArray(stateInfoSinceLastExecutionMap)) {
    if (Pair.token0.address <= Pair.token1.address) {
      ;[currentSalesRateInPut, currentSalesRateOutPut] = [currentSalesRateA.div(10000), currentSalesRateB.div(10000)]
    } else {
      ;[currentSalesRateInPut, currentSalesRateOutPut] = [currentSalesRateB.div(10000), currentSalesRateA.div(10000)]
    }
  } else {
    ;[currentSalesRateInPut, currentSalesRateOutPut] = [undefined, undefined]
  }

  const handleBlockHeightChanges = useCallback(
    (blockValue: string) => {
      if (
        !(Number.isInteger(Number(blockValue)) && Number(blockValue) > lastVirtualOrderBlock) &&
        Number(blockValue) <= Number(currentBlockNumber)
      )
        return
      dispatch(blockInput({ blockValue }))
    },
    [currentBlockNumber, dispatch, lastVirtualOrderBlock]
  )

  const inputToken = wrappedCurrency(currencies[Field.INPUT] ?? undefined, chainId ?? undefined)
  const outputToken = wrappedCurrency(currencies[Field.OUTPUT] ?? undefined, chainId ?? undefined)
  const checkMantleBase = chainId === 5000 || chainId === 5001

  useEffect(() => {
    if (lastVirtualOrderBlock > inputValue) {
      setInputValue(lastVirtualOrderBlock + 1)
    }
  }, [inputValue, lastVirtualOrderBlock])

  useEffect(() => {
    if (lastVirtualOrderBlock > 0) {
      setPreviousBlock(lastVirtualOrderBlock)
    }
  }, [lastVirtualOrderBlock])

  return (
    <ListWrapper>
      <SwapLongTermHeader title={'TWAMM State Info'} loading={!!(Pair && !reserveInPut && !reserveOutPut)} />
      {
        <ItemsBox>
          {/* <ItemWrapperRow style={{ flexDirection: 'row' }}>
            <ItemTitle>Pair Analytics</ItemTitle>
            <ExternalLink href={'https://info.pulsarswap.com/pool/' + pairAddr}>
              <LinkIcon size={18}></LinkIcon>
            </ExternalLink>
          </ItemWrapperRow> */}
          <ItemWrapperRow style={{ flexDirection: 'row' }}>
            <ItemTitle>Pair Analytics</ItemTitle>
            <ExternalLink href={chainId ? getEtherscanLink(chainId, pairAddr, 'address') : ''}>
              <LinkIcon size={18}></LinkIcon>
            </ExternalLink>
          </ItemWrapperRow>
          <ItemWrapper>
            <ItemTitle>Last Execute Virtual Orders</ItemTitle>
            {/* <ItemDetail>{lastVirtualOrderBlock} block height</ItemDetail> */}
            {lastVirtualOrderBlock <= 0 ? (
              <ItemDetail>{lastVirtualOrderBlock} block height</ItemDetail>
            ) : (
              <ItemDetail>
                <ExternalLink
                  style={{ display: 'inline-block', color: '#3680E7' }}
                  href={
                    chainId && lastVirtualOrderBlock
                      ? getEtherscanLink(chainId, lastVirtualOrderBlock.toString(), 'block')
                      : ''
                  }
                >
                  {lastVirtualOrderBlock + ' '}
                </ExternalLink>
                &nbsp; block height
              </ItemDetail>
            )}
          </ItemWrapper>
          <ItemWrapper>
            <ItemTitle>Embedded AMM Reserves</ItemTitle>
            <ItemDetail>
              <>
                {!!inputToken && reserveInPut ? new TokenAmount(inputToken, reserveInPut).toSignificant(10) : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.INPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol ?? '-'
                    : currencies[Field.INPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.INPUT]?.symbol ?? '-'}
                </ItemInfo>
              </>
              {/* 
              <ItemInfo>
                {currencies[Field.INPUT]?.symbol === 'WETH' ? 'ETH' : currencies[Field.INPUT]?.symbol ?? '-'}
              </ItemInfo> */}
            </ItemDetail>
            <ItemDetail>
              <>
                {!!outputToken && reserveOutPut ? new TokenAmount(outputToken, reserveOutPut).toSignificant(10) : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.OUTPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol ?? '-'
                    : currencies[Field.OUTPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.OUTPUT]?.symbol ?? '-'}
                </ItemInfo>
              </>
              {/* {(resTrade?.Pair ?? Pair)?.reserve1?.toSignificant(10) ?? '-'}{' '}

              <ItemInfo>
                {currencies[Field.OUTPUT]?.symbol === 'WETH' ? 'ETH' : currencies[Field.OUTPUT]?.symbol ?? '-'}
              </ItemInfo> */}
            </ItemDetail>
            <ItemDetail>
              <>
                {!!outputToken && reserveOutPut && !!inputToken && reserveInPut.gt(BigNumber.from(0))
                  ? new TokenAmount(outputToken, reserveOutPut)
                      .divide(new TokenAmount(inputToken, reserveInPut))
                      .toSignificant(6)
                  : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.OUTPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol ?? '-'
                    : currencies[Field.OUTPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.OUTPUT]?.symbol ?? '-'}{' '}
                  per{' '}
                  {checkMantleBase
                    ? currencies[Field.INPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol ?? '-'
                    : currencies[Field.INPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.INPUT]?.symbol ?? '-'}
                </ItemInfo>
              </>
            </ItemDetail>
            {/* <ItemDetail>
              <>
                {!!outputToken && reserveOutPut && !!inputToken && reserveInPut
                  ? AMMPrice.toFixed(AMMPrice > 1 ? 2 : 4)
                  : '-'}
                <ItemInfo>
                  {currencies[Field.OUTPUT]?.symbol === 'WETH' ? 'ETH' : currencies[Field.OUTPUT]?.symbol ?? '-'} per{' '}
                  {currencies[Field.INPUT]?.symbol === 'WETH' ? 'ETH' : currencies[Field.INPUT]?.symbol ?? '-'}
                </ItemInfo>
              </>
            </ItemDetail> */}
          </ItemWrapper>
          <ItemWrapper>
            <ItemTitle>Order Pool Reserves</ItemTitle>
            <ItemDetail>
              {userPairABalance?.toSignificant(10)} -{' '}
              {!!inputToken && reserveInPut ? new TokenAmount(inputToken, reserveInPut).toSignificant(10) : '-'}{' '}
              <ItemInfo>
                {checkMantleBase
                  ? currencies[Field.INPUT]?.symbol === 'WMNT'
                    ? 'MNT'
                    : currencies[Field.INPUT]?.symbol === 'ETH'
                    ? 'MNT'
                    : currencies[Field.INPUT]?.symbol ?? '-'
                  : currencies[Field.INPUT]?.symbol === 'WETH'
                  ? 'ETH'
                  : currencies[Field.INPUT]?.symbol ?? '-'}
              </ItemInfo>
            </ItemDetail>
            <ItemDetail>
              {userPairBBalance?.toSignificant(10)} -{' '}
              {!!outputToken && reserveOutPut ? new TokenAmount(outputToken, reserveOutPut).toSignificant(10) : '-'}{' '}
              <ItemInfo>
                {checkMantleBase
                  ? currencies[Field.OUTPUT]?.symbol === 'WMNT'
                    ? 'MNT'
                    : currencies[Field.OUTPUT]?.symbol === 'ETH'
                    ? 'MNT'
                    : currencies[Field.OUTPUT]?.symbol ?? '-'
                  : currencies[Field.OUTPUT]?.symbol === 'WETH'
                  ? 'ETH'
                  : currencies[Field.OUTPUT]?.symbol ?? '-'}
              </ItemInfo>
            </ItemDetail>
          </ItemWrapper>
          <ItemWrapper>
            <ItemTitle>Order Pool Sales Rate</ItemTitle>
            <ItemDetail>
              <>
                {!!inputToken && currentSalesRateInPut
                  ? new TokenAmount(inputToken, currentSalesRateInPut).toSignificant(6)
                  : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.INPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol ?? '-'
                    : currencies[Field.INPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.INPUT]?.symbol ?? '-'}{' '}
                  / block
                </ItemInfo>
              </>
            </ItemDetail>
            <ItemDetail>
              <>
                {!!outputToken && currentSalesRateOutPut
                  ? new TokenAmount(outputToken, currentSalesRateOutPut).toSignificant(6)
                  : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.OUTPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol ?? '-'
                    : currencies[Field.OUTPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.OUTPUT]?.symbol ?? '-'}{' '}
                  / block
                </ItemInfo>
              </>
            </ItemDetail>
            <ItemDetail>
              <>
                {!!outputToken && currentSalesRateOutPut && !!inputToken && currentSalesRateInPut.gt(BigNumber.from(0))
                  ? new TokenAmount(outputToken, currentSalesRateOutPut)
                      .divide(new TokenAmount(inputToken, currentSalesRateInPut))
                      .toSignificant(6)
                  : '-'}
                <ItemInfo>
                  {checkMantleBase
                    ? currencies[Field.OUTPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.OUTPUT]?.symbol ?? '-'
                    : currencies[Field.OUTPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.OUTPUT]?.symbol ?? '-'}{' '}
                  per{' '}
                  {checkMantleBase
                    ? currencies[Field.INPUT]?.symbol === 'WMNT'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol === 'ETH'
                      ? 'MNT'
                      : currencies[Field.INPUT]?.symbol ?? '-'
                    : currencies[Field.INPUT]?.symbol === 'WETH'
                    ? 'ETH'
                    : currencies[Field.INPUT]?.symbol ?? '-'}
                </ItemInfo>
              </>
            </ItemDetail>
          </ItemWrapper>
          <ItemWrapper>
            <ItemTitle>
              <XIconWrapper
                bg={'linear-gradient(90deg, #0261F5 0%, #6B5CFF 100%);'}
                onClick={() => {
                  onExecuteVirtualOrders()
                }}
              >
                <LinkIcon size={17} />
                <div id="show-note">Execute Virtual Orders</div>
              </XIconWrapper>
              <PIConfirm
                isOpen={showConfirm}
                title="Select Block  Height"
                contentStyle={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                content={
                  // <InputNumber
                  //   min={lastVirtualOrderBlock + 1}
                  //   max={currentBlockNumber}
                  //   style={{ width: '100%' }}
                  //   value={inputValue}
                  //   onChange={onChange}
                  // />
                  <Row style={{ width: '100%', justifyContent: 'center' }}>
                    <Col span={10} offset={2}>
                      <Slider
                        min={previousBlock + 1}
                        max={currentBlockNumber}
                        onChange={onChange}
                        value={typeof inputValue === 'number' ? inputValue : previousBlock + 1}
                      />
                    </Col>
                    <Col span={10}>
                      <InputNumber
                        min={previousBlock + 1}
                        max={currentBlockNumber}
                        style={{ margin: '0 16px', width: '80%' }}
                        value={inputValue}
                        onChange={onChange}
                      />
                    </Col>
                  </Row>
                }
                onConfirm={async () => {
                  console.log('Confirm')
                  // executeVirtualOrdersWrapper
                  if (
                    !router ||
                    !currentBlockNumber ||
                    inputValue <= lastVirtualOrderBlock ||
                    inputValue > currentBlockNumber
                  )
                    return
                  let estimate = router.estimateGas['executeVirtualOrdersWrapper']
                  let method = router['executeVirtualOrdersWrapper']
                  let args = [Pair?.liquidityToken?.address, inputValue]
                  let summary = `Execute Virtual Orders to ${inputValue} Block Height`
                  await estimate(
                    ...args
                    // , value ? { value } : {}
                  ).then(estimatedGasLimit =>
                    method(...args, {
                      // ...(value ? { value } : {}),
                      gasLimit: calculateGasMargin(estimatedGasLimit)
                    })
                      .then((response: TransactionResponse) => {
                        addTransaction(response, {
                          summary
                        })

                        // ReactGA.event({
                        //   category: 'Liquidity',
                        //   action: 'Remove',
                        //   label: [currencyA?.symbol, currencyB?.symbol].join('/')
                        // })
                      })
                      .catch((error: Error) => {
                        // we only care if the error is something _other_ than the user rejected the tx
                        console.error(error)
                      })
                  )
                  setConfirmState({ showConfirm: false })
                }}
                onDismiss={() => {
                  console.log('Cancel')
                  setConfirmState({ showConfirm: false })
                }}
              />
            </ItemTitle>
          </ItemWrapper>
          {/* <ItemWrapper>
          <ItemTitle>Order Pool Reserves</ItemTitle>
          <ItemDetail>
            {resTrade?.pair?.reserveInPut.toSignificant(4) ?? '-'}{' '}
            <ItemInfo>{currencies[Field.INPUT]?.symbol ?? '-'}</ItemInfo>
          </ItemDetail>
          <ItemDetail>
            {resTrade?.pair?.reserveOutPut.toSignificant(4) ?? '-'}{' '}
            <ItemInfo>{currencies[Field.OUTPUT]?.symbol ?? '-'}</ItemInfo>
          </ItemDetail>
        </ItemWrapper>
        <ItemWrapper>
          <ItemTitle>Order Pool Sales Rate</ItemTitle>
          <ItemDetail>123 {currencies[Field.INPUT]?.symbol ?? '-'} / block</ItemDetail>
          <ItemDetail>234 {currencies[Field.OUTPUT]?.symbol ?? '-'} / block</ItemDetail>
        </ItemWrapper> */}
        </ItemsBox>
      }
    </ListWrapper>
  )
}
