// @ts-nocheck

import { SupportedChainId, ChainId } from './chains'
import { Currency, Token } from '@uniswap/sdk'
// /**
//  * Represents an ERC20 token with a unique address and some metadata.
//  */
// export declare class Token extends Currency {
//   readonly chainId: ChainId | SupportedChainId
//   readonly address: string
//   constructor(chainId: ChainId | SupportedChainId, address: string, decimals: number, symbol?: string, name?: string)
//   /**
//    * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
//    * @param other other token to compare
//    */
//   equals(other: Token | UniToken): boolean
//   /**
//    * Returns true if the address of this token sorts before the address of the other token
//    * @param other other token to compare
//    * @throws if the tokens have the same address
//    * @throws if the tokens are on different chains
//    */
//   sortsBefore(other: Token | UniToken): boolean
// }
// /**
//  * Compares two currencies for equality
//  */
/**
 * Known WETH9 implementation addresses, used in our implementation of Ether#wrapped
 */
export const WETH9: { [chainId: number]: Token } = {
  [1]: new Token(1, '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2', 18, 'WETH', 'Wrapped Ether'),
  [3]: new Token(3, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH', 'Wrapped Ether'),
  [4]: new Token(4, '0xc778417E063141139Fce010982780140Aa0cD5Ab', 18, 'WETH', 'Wrapped Ether'),
  [5]: new Token(5, '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', 18, 'WETH', 'Wrapped Ether'),
  [42]: new Token(42, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped Ether'),

  [10]: new Token(10, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [69]: new Token(69, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),

  // [5000]: new Token(5000, '', 18, 'WMNT', 'Wrapped MNT Token'), //WMNT
  [5001]: new Token(5001, '0x8734110e5e1dcF439c7F549db740E546fea82d66', 18, 'WMNT', 'Wrapped MNT Token'), //WMNT

  [42161]: new Token(42161, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'WETH', 'Wrapped Ether'),
  [421611]: new Token(421611, '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681', 18, 'WETH', 'Wrapped Ether')
}

export const WETH: { [chainId: number]: Token | undefined } = {
  ...(WETH9 as Record<SupportedChainId, Token>),
  [SupportedChainId.OPTIMISM]: new Token(
    SupportedChainId.OPTIMISM,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.OPTIMISM_GOERLI]: new Token(
    SupportedChainId.OPTIMISM_GOERLI,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  // [SupportedChainId.MANTLE]: new Token(SupportedChainId.MANTLE, '', 18, 'WMNT', 'Wrapped MNT Token'), //WMNT
  [SupportedChainId.MANTLE_GOERLI]: new Token(
    SupportedChainId.MANTLE_GOERLI,
    '0x8734110e5e1dcF439c7F549db740E546fea82d66',
    18,
    'WMNT',
    'Wrapped MNT Token'
  ), //WMNT
  [SupportedChainId.ARBITRUM_ONE]: new Token(
    SupportedChainId.ARBITRUM_ONE,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.ARBITRUM_GOERLI]: new Token(
    SupportedChainId.ARBITRUM_GOERLI,
    '0x6d44bb7122c831a749cc0006cd371c123bc2aca4',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.ARBITRUM_RINKEBY]: new Token(
    SupportedChainId.ARBITRUM_RINKEBY,
    '0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [SupportedChainId.POLYGON]: new Token(
    SupportedChainId.POLYGON,
    '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [SupportedChainId.POLYGON_MUMBAI]: new Token(
    SupportedChainId.POLYGON_MUMBAI,
    '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    18,
    'WMATIC',
    'Wrapped MATIC'
  ),
  [SupportedChainId.CELO]: new Token(
    SupportedChainId.CELO,
    '0x471ece3750da237f93b8e339c536989b8978a438',
    18,
    'CELO',
    'Celo native asset'
  ),
  [SupportedChainId.CELO_ALFAJORES]: new Token(
    SupportedChainId.CELO_ALFAJORES,
    '0xf194afdf50b03e69bd7d057c1aa9e10c9954e4c9',
    18,
    'CELO',
    'Celo native asset'
  )
}
